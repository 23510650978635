exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bmwdata-js": () => import("./../../../src/pages/bmwdata.js" /* webpackChunkName: "component---src-pages-bmwdata-js" */),
  "component---src-pages-booking-index-js": () => import("./../../../src/pages/booking/index.js" /* webpackChunkName: "component---src-pages-booking-index-js" */),
  "component---src-pages-complete-health-checkup-chennai-js": () => import("./../../../src/pages/complete-health-checkup-chennai.js" /* webpackChunkName: "component---src-pages-complete-health-checkup-chennai-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-health-information-js": () => import("./../../../src/pages/health-information.js" /* webpackChunkName: "component---src-pages-health-information-js" */),
  "component---src-pages-healthcare-bkp-js": () => import("./../../../src/pages/healthcare-bkp.js" /* webpackChunkName: "component---src-pages-healthcare-bkp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-doctors-js": () => import("./../../../src/pages/our-doctors.js" /* webpackChunkName: "component---src-pages-our-doctors-js" */),
  "component---src-pages-our-speciality-js": () => import("./../../../src/pages/our-speciality.js" /* webpackChunkName: "component---src-pages-our-speciality-js" */),
  "component---src-pages-packagethank-js": () => import("./../../../src/pages/packagethank.js" /* webpackChunkName: "component---src-pages-packagethank-js" */),
  "component---src-pages-packagethankyou-js": () => import("./../../../src/pages/packagethankyou.js" /* webpackChunkName: "component---src-pages-packagethankyou-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sleep-disordered-breathing-clinic-js": () => import("./../../../src/pages/sleep-disordered-breathing-clinic.js" /* webpackChunkName: "component---src-pages-sleep-disordered-breathing-clinic-js" */),
  "component---src-pages-social-media-policy-js": () => import("./../../../src/pages/social-media-policy.js" /* webpackChunkName: "component---src-pages-social-media-policy-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-upcoming-event-js": () => import("./../../../src/pages/upcoming-event.js" /* webpackChunkName: "component---src-pages-upcoming-event-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/Templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-book-by-doctor-js": () => import("./../../../src/Templates/book-by-doctor.js" /* webpackChunkName: "component---src-templates-book-by-doctor-js" */),
  "component---src-templates-doctor-js": () => import("./../../../src/Templates/doctor.js" /* webpackChunkName: "component---src-templates-doctor-js" */),
  "component---src-templates-event-js": () => import("./../../../src/Templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-media-jsx": () => import("./../../../src/Templates/media.jsx" /* webpackChunkName: "component---src-templates-media-jsx" */),
  "component---src-templates-speciality-js": () => import("./../../../src/Templates/speciality.js" /* webpackChunkName: "component---src-templates-speciality-js" */)
}

